
.Rondleiding {
    margin-top: 60px;
    /*margin-bottom: 70px;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-height: 100vh;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.tour-container {
    max-width: 800px;
    padding: 20px;
    background-color: #f8f8f8;
    /*border: 1px solid #ccc;*/
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 20px auto;


}
.header {
    text-align: center;
    margin-bottom: 20px;
}

.content {
    margin-bottom: 1rem;
    /*line-height: 2rem;*/

}

.explorer-full-explanation-link {
    color: #1A73E8;

}


.rondleiding-footer {
    text-align: center;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: sticky;
    bottom: 0rem;

}

.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;


}

.prev-button,
.next-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.prev-button:hover,
.next-button:hover {
    background-color: #0056b3;
}

.book-consultancy-button {
    padding: 10px 20px;
    background-color: #4285f4; /* Google Blue for action buttons */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.book-consultancy-button:hover {
    background-color: #1c6ef2; /* Darker shade on hover */
}

.finder-container {
    max-height: 300px; /* Adjust as per your content and design */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-top: 10px;
}
