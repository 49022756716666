.SendFacebookMessage {
    display: block;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.fb-message-input {
    padding: 10px;
    font-size: 16px;
    margin-right: 10px;
    width: 300px;
}

.fb-send-button {
    display: flex;
    align-items: center;
    background-color: #0078FF;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;

}

.fb-send-button:hover {
    background-color: #0063CC;
}
