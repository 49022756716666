.m-header {
    background-color: #1A73E8;
    color: #54B1FFFF;
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 100%;
    z-index: 1000;
    height: 4rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Adding shadow below */

}

.header-logo {
    height: auto;
    width: 14rem;
    border-radius: 5px;
    margin-left: 0.5rem;
    z-index: 2;

}

.m-header h1 {
    font-size: 21px;
    margin: 0;
    color: whitesmoke;
}

.title-and-menu {
    display: flex;
    align-items: center;
}

.title-and-menu-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: inherit; /* Ensure it inherits the color */
}

.nav-links {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1A73E8;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    text-align: center;
    transform: translateX(-120%);
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;
    z-index: 1;
    gap: 3rem;
}

.nav-links.open {
    transform: translate(0, 0);
}

.play-nav-open-menu {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.m-header ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.m-header li {
    margin: 10px 0;
}

.m-header a {
    position: relative; /* Make the link a positioned element */
    text-decoration: none;
    color: white;
    font-size: 17px;
    transition: color 0.3s ease;
}

.m-header .nav-link:hover,
.m-header .nav-link.active {
    color: greenyellow;
}

.m-header .nav-link::after,
.m-header .nav-link.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px; /* Adjust to ensure the underline appears just below the text */
    width: 0;
    height: 2px; /* Adjust the height of the underline */
    background-color: greenyellow; /* Color of the underline */
    transition: width 0.3s ease-in-out;
}

.m-header .nav-link:hover::after,
.m-header .nav-link.active::after {
    width: 100%; /* Full width on hover */
}

/* Start Sollicitatie Header Button */
.start-sollicitatie-header-button {
    background-color: #28a745; /* Green background color */
    color: #ffffff; /* White text color */
    padding: 10px 20px; /* Padding for the button */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    font-size: 1rem; /* Font size */
    font-weight: bold; /* Bold text */
    text-decoration: none; /* No underline for links */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Transition effects */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); /* Subtle shadow for depth */
}

/* Hover Effect */
.start-sollicitatie-header-button:hover {
    background-color: #218838; /* Darker green on hover */
    transform: translateY(-2px); /* Lift effect on hover */
}

/* Active Effect */
.start-sollicitatie-header-button:active {
    background-color: #1e7e34; /* Even darker green on click */
    transform: translateY(0); /* Reset lift effect */
}


.menu-toggle {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    z-index: 2;
    margin-left: 50px;
}

.bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 5px 0;
    transition: transform 0.3s ease, background-color 0.3s ease;


}

.menu-toggle.open .bar:first-child {
    transform: rotate(-45deg) translate(-11px, 13px);

}

.menu-toggle.open .bar:nth-child(2) {
    opacity: 0;
}

.menu-toggle.open .bar:last-child {
    transform: rotate(45deg) translate(-5px, -6px);


}



@media screen and (max-width: 769px) {
    .action-button {
        margin-left: 0;
    }
}

@media screen and (min-width: 769px) {
    .m-header {
        flex-direction: row;
        align-items: center;
    }

    .menu-toggle {
        display: none;
    }

    .nav-links {
        position: static;
        transform: none;
        flex-direction: row;
        background-color: transparent;
        width: auto;
        height: auto;
        overflow-y: visible;
    }

    .m-header ul {
        flex-direction: row;
    }

    .m-header li {
        margin: 0 15px;
    }

    .m-header a {
        font-size: 16px;
    }

    .play-nav-open-menu {
        display: flex;
        flex-direction: row;
        align-items: center;

    }
}

