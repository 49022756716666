.open-sollicitation {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 100px auto;
}

.open-sollicitation h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: #333;
}

.sollicitation-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

.form-group textarea {
    resize: vertical;
    height: 100px;
}

.send-whatsapp-btn {
    padding: 12px 25px;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    background-color: #4db935;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.25s ease;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

}

.send-whatsapp-btn:hover {
    background-color: #3c9a2e;
}

.send-whatsapp-btn:active {
    background-color: #2b6a1e;
}
