.TalentPool {
    margin-top: 80px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;

}

.talent-pool-container {
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.talent-pool-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

.talent-pool-subtitle {
    font-size: 1.5rem;
    color: #666;
    margin-bottom: 30px;
}

.TalentPool button {
}

.TalentPool button:hover {
    background-color: #0056b3;
}

.talent-pool-sign-up {
    background-color: #28a745;
    display: block;
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    font-size: 1rem;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

}

.talent-pool-sign-up:hover {
    background-color: #218838;
}

.talent-pool-examine {
    display: block;
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    font-size: 1rem;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

}

.talent-pool-examine:hover {
    background-color: #0056b3;
}
