/* General Job Board Styles */
.job-board {
    color: #333;
    margin-top: 60px;
    font-family: 'Arial', sans-serif;
    max-width: 1200px; /* Max width to constrain content */
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
    padding: 0 20px; /* Padding to avoid content touching edges */
    box-sizing: border-box; /* Include padding in width calculation */
}

/* Header Styles */
.job-board-header {
    background-color: #2494f1;
    color: white;
    padding: 20px 30px; /* Increased padding for a more balanced look */
    text-align: center;
    font-size: 2em;
    font-weight: bold;
    border-radius: 8px; /* Slight rounding for a modern look */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Control Section Styles */
.job-board-controls {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    width: 100%;
    flex-wrap: wrap;
    gap: 1.5rem; /* Increased gap for better spacing */
}

/* Filter Bar Styles */
.job-board-controls-filter-by-typing {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px; /* Added padding for a better feel */
    /*border: 1px solid #ccc;*/
    /*border-radius: 8px; !* Rounded edges for modern styling *!*/
    /*background-color: #f9f9f9; !* Light background for contrast *!*/
    /*width: 100%;*/
    /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); !* Subtle shadow *!*/
}

.job-board-controls input,
.job-board-controls select {
    margin: 0 4px;
    padding: 10px;
    font-size: 1em;
    border-radius: 4px;
    border: 1px solid #ddd; /* Consistent border for input/select */
    transition: border-color 0.3s ease;
}

/* Input Focused State */
.job-board-controls input:focus,
.job-board-controls select:focus {
    /*border-color: #4CAF50; !* Highlighted border on focus *!*/
    outline: none; /* Remove default outline */
}

/* Main Content Styles */
.job-board-main {
    padding: 20px;
}

/* Job Card Styles */
.job-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px; /* Increased padding for comfort */
    margin: 15px 0; /* Increased margin for spacing */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Enhanced shadow for depth */
    transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transitions */
    background-color: #fff; /* White background for a clean look */
}

/* Job Card Hover Effect */
.job-card:hover {
    transform: translateY(-5px); /* Slight lift on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.job-title {
    font-size: 1.8em; /* Increased size for emphasis */
    margin: 0 0 15px;
    color: #333;
    font-weight: bold;
}

.job-company,
.job-location,
.job-description,
.job-salary,
.job-experience,
.job-education {
    margin: 5px 0;
    font-size: 1em;
    color: #555; /* Softer text color for readability */
}

/* Job Actions Section */
.job-actions {
    margin-top: 20px;
    display: flex;
    gap: 15px; /* Increased gap for better spacing */
    justify-content: flex-end; /* Align actions to the right */
}

/* Job Action Buttons */
.job-action-whatsapp-button {
    padding: 12px 25px; /* Enhanced padding for better clickability */
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    border-radius: 8px; /* More rounded for modern feel */
    color: #ffffff; /* White text for clear contrast */
    background-color: #4db935; /* Red for a stronger call to action */
    transition: background-color 0.3s ease, transform 0.25s ease;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

/* Hover and Active States for Buttons */
.job-action-whatsapp-button:hover {
    background-color: #3c9a2e; /* Darker red on hover */
    transform: translateY(-4px); /* Lift button on hover */
}

.job-action-whatsap-button:active {
    background-color: #2b6a1e; /* Even darker red on click */
    transform: translateY(0); /* Return on click */
}

/* Job Action Buttons */
.job-action-mail-button {
    padding: 12px 25px; /* Enhanced padding for better clickability */
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    border-radius: 8px; /* More rounded for modern feel */
    color: #ffffff; /* White text for clear contrast */
    background-color: #e74c3c; /* Red for a stronger call to action */
    transition: background-color 0.3s ease, transform 0.25s ease;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

/* Hover and Active States for Buttons */
.job-action-mail-button:hover {
    background-color: #c0392b; /* Darker red on hover */
    transform: translateY(-4px); /* Lift button on hover */
}

.job-action-mail-button:active {
    background-color: #a93226; /* Even darker red on click */
    transform: translateY(0); /* Return on click */
}


/* Additional Desktop Styles */
@media (min-width: 768px) {
    .job-board {
        padding: 0 40px; /* Increased padding for larger screens */
    }

    .job-board-header {
        font-size: 2.5em; /* Larger font size for desktop */
        padding: 30px 50px; /* More padding for balance */
    }

    .job-board-controls {
        gap: 2rem; /* More spacing for controls */
    }

    .job-board-controls-filter-by-typing {
        padding: 15px 20px; /* Larger padding for better visibility */
        max-width: 600px; /* Restrict width for better alignment */
    }

    .job-card {
        padding: 25px; /* Increased padding for better readability */
        margin: 20px 0; /* More margin for spacing */
    }

    .job-title {
        font-size: 2em; /* Larger font size for emphasis */
    }
}


