/* General Layout */
.dashboard-container {
    display: flex;
    height: 100vh;
    font-family: Arial, sans-serif;
    margin-top: 3rem;
}

.dashboard-sidebar {
    width: 250px;
    background-color: #2c3e50;
    color: white;
    padding: 20px;
}

.dashboard-sidebar nav ul {
    list-style-type: none;
    padding: 0;
}

.dashboard-sidebar nav ul li {
    margin: 20px 0;
    cursor: pointer;
}

.dashboard-sidebar nav ul li:hover {
    color: #3498db;
}

.dashboard-main {
    flex: 1;
    padding: 20px;
    background-color: #ecf0f1;
    overflow-y: auto;
}

.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.dashboard-header h1 {
    margin: 0;
}

.dashboard-controls button {
    margin-left: 10px;
    padding: 10px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.dashboard-controls button:hover {
    background-color: #2980b9;
}

/* Statistics Section */
.dashboard-stats {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.stat-card {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 30%;
}

.stat-card h2 {
    margin-bottom: 10px;
}

/* Charts Section */
.dashboard-charts {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.chart {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 48%;
}

/* Table Section */
.dashboard-table {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dashboard-table table {
    width: 100%;
    border-collapse: collapse;
}

.dashboard-table th, .dashboard-table td {
    padding: 15px;
    text-align: left;
}

.dashboard-table th {
    background-color: #3498db;
    color: white;
}

.dashboard-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* Vacatures Section */
.vacatures-section {
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.vacatures-section table {
    width: 100%;
    border-collapse: collapse;
}

.vacatures-section th, .vacatures-section td {
    padding: 15px;
    text-align: left;
}

.vacatures-section th {
    background-color: #3498db;
    color: white;
}

.vacatures-section tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* Settings Section */
.dashboard-settings {
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Buttons for actions */
button {
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-right: 5px;
}

button:hover {
    opacity: 0.8;
}

button.delete-button {
    background-color: #e74c3c;
    color: white;
}

button.delete-button:hover {
    background-color: #c0392b;
}

button {
    background-color: #3498db;
    color: white;
}

button:hover {
    background-color: #2980b9;
}
