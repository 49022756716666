.RequestCallAppointment {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 20px;
}

.name-input, .phone-input, .call-message-input {
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    width: 300px;
}

.call-message-input {
    height: 100px;
}

.request-call-submit-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
}

.request-call-submit-button:hover {
    background-color: #218838;
}
