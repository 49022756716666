/* Footer styles */
footer {
    position: relative;
    background-color: #f5f5f5; /* Light grey background */
    color: #202124; /* Google grey text color */
    padding: 2rem 1rem; /* Padding for the footer */
    text-align: center;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #e0e0e0; /* Light grey border on top */
}

.footer-logo {
    position: absolute;
    top: -2.3rem; /* Adjust as needed */
    right: calc(100% + 1rem); /* Moves 2rem to the right of the right edge of the footer */
    transform: translateX(100%); /* Alternative to right: calc(100% + 2rem); */
}

.footer-logo img {
    width: 4.5rem; /* Adjust the width of your logo as needed */
    margin-bottom: 10px;
    border-radius: 0 10px 10px 0;

}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.footer-messages {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.footer-messages-align-column {
    display: flex;
    flex-direction: column;
}

.footer-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
}

.footer-links a {
    color: #1a73e8; /* Google blue link color */
    text-decoration: none;
    font-size: 14px;
}

.footer-links a:hover {
    text-decoration: underline;
}

.footer-links p {
    margin: 0;
    font-size: 14px;
}

.send-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1a73e8; /* Google blue button color */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.send-button:hover {
    background-color: #174ea6; /* Darker blue on hover */
}

.message-input, .subject-input, .phone-input, .body-input {
    padding: 10px;
    font-size: 14px;
    margin-bottom: 10px;
    width: 200px;
    border: 1px solid #e0e0e0; /* Light grey border */
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s ease;
}

.message-input:focus, .subject-input:focus, .phone-input:focus, .body-input:focus {
    border-color: #1a73e8; /* Blue border on focus */
}

.body-input {
    height: 60px;
}

@media screen and (max-width: 768px) {
    .footer-messages {
        flex-direction: column;
        align-items: center;
    }

    .footer-links {
        flex-direction: row;
        align-items: center;
    }
}
