/* WorkCategories.css */

.work-categories-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #f3f4f6; /* Light background */
    border-top-left-radius: 5rem;
    border-top-right-radius: 5rem;
    margin-top: 1rem;
}

.category-buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 2rem;
}

.category-item {
    margin: 0.5rem;
}

.category-link-item {
    display: block;
    padding: 1rem 1.5rem;
    background-color: #007bff; /* Bootstrap primary color */
    color: #fff;
    border-radius: 6px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.category-link-item:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

.overview-section {
    text-align: center;
    max-width: 600px;
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.overview-message-title {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
}

.category-buttons {
    margin-top: 1.5rem;
}

.category-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.5rem;
    background-color: #096c2e; /* Bootstrap success color */
    color: #fff;
    border-radius: 6px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
    margin: 0.5rem; /* Margin for spacing between buttons */
}

.category-button:hover {
    background-color: #218838; /* Darker green on hover */
}

.vacatures-bekijken-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.5rem;
    background-color: #283da7; /* Bootstrap success color */
    color: #fff;
    border-radius: 6px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
    margin: 0.5rem; /* Margin for spacing between buttons */
}
