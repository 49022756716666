.TalentPoolExamine {
    min-height: 100vh;
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;

}

.talent-pool-category-examine-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.talent-pool-category-examine-subtitle {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
}

.talent-pool-category-examine-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.talent-pool-category-examine-list-item {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    margin: 10px 0;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.talent-pool-category-examine-list-item:hover {
    background-color: #0056b3;
}
