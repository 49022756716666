/*!**/
/*.SendEmailMessage {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    padding: 20px;*/
/*}*/

/*.subject-input, .body-input {*/
/*    padding: 10px;*/
/*    font-size: 16px;*/
/*    margin-bottom: 10px;*/
/*    width: 300px;*/
/*}*/

/*.body-input {*/
/*    height: 100px;*/
/*}*/

/*.send-button {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    background-color: #0078FF;*/
/*    color: white;*/
/*    border: none;*/
/*    padding: 10px 20px;*/
/*    font-size: 16px;*/
/*    cursor: pointer;*/
/*    border-radius: 5px;*/
/*}*/

/*.send-button:hover {*/
/*    background-color: #0063CC;*/
/*}*/
/**!*/
