/* StartSollicitatie.css */

.start-sollicitatie-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: #abc2f5; /* light background */
    border-bottom-right-radius: 5rem;
    border-top-left-radius: 5rem;
    margin-top: 1rem;
}

.sollicitatie-content {
    text-align: center;
    max-width: 500px;
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.intro-text {
    font-size: 1.25rem;
    color: #333;
    margin-bottom: 1.5rem;
    line-height: 1.6;
}

.start-sollicitatie-button {
    display: inline-flex;
    align-items: center;
    background-color: #096c2e; /* WhatsApp green */
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.start-sollicitatie-button:hover {
    background-color: #1ebf58; /* darker WhatsApp green for hover */
}

.contact-text {
    font-size: 1rem;
    color: #666;
    margin-top: 1.5rem;
    line-height: 1.6;
}
