/* SuccesfulInquiry.css */

.succesful-inquiry-container {
    text-align: center;
    margin: 100px auto;
    padding: 20px;
    max-width: 600px;
    background-color: #f0f9ff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.succesful-inquiry-container h1 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 20px;
}

.succesful-inquiry-container p {
    font-size: 1.2rem;
    color: #34495e;
    margin-bottom: 20px;
}

.back-to-home-btn {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.back-to-home-btn:hover {
    background-color: #2980b9;
}
